import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
import {VueSlideToggle} from 'vue-slide-toggle';
import mainSelect from '@/components/main-select/index.vue';
import mainButton from '@/components/main-button/index.vue';
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import autocomplete from '@/components/autocomplete/index.vue';


export default {
    name: "legal-accordion",
    props: {
        legalPayload: {
            type: Object,
            default: () => {
            }
        }
    },
    components: {
        VueSlideToggle,
        mainSelect,
        mainButton,
        autocomplete
    },
    validations: {
        legalPayload: {
            code: {
                minLength: minLength(8),
                maxLength: maxLength(14)
            },
            edrpo: {
                minLength: minLength(8),
                maxLength: maxLength(14)
            },
            legalAddress: {
                required,
                minLength: minLength(3)
            },
            address: {
                required,
                minLength: minLength(3)
            }
        }
    },
    data() {
        return {
            expanded: false,
            legalEdit: false,
            currentMethod: '',
            delivery: '',
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({
            type: 'profile/ownershipTypes',
            towns: 'order/townsList',
            isButtonLoader: 'profile/isLoadingButtonLoader'
        }),
        codeErrors() {
            let error = [];
            if (!this.$v.legalPayload.code.$dirty) {
                return error;
            }
            if (!this.$v.legalPayload.code.minLength) {
                error.push(this.$t('validation.minLength', { 'size': 8 }));
            }
            if (!this.$v.legalPayload.code.maxLength) {
                error.push(this.$t('validation.maxLength', { 'size': 14 }));
            }
            return error;
        },
        edrpoErrors() {
            let error = [];
            if (!this.$v.legalPayload.edrpo.$dirty) {
                return error;
            }
            if (!this.$v.legalPayload.edrpo.minLength) {
                error.push(this.$t('validation.minLength', { 'size': 8 }));
            }
            if (!this.$v.legalPayload.edrpo.maxLength) {
                error.push(this.$t('validation.maxLength', { 'size': 14 }));
            }
            return error;
        },
        legalAddressErrors() {
            let error = [];
            if (!this.$v.legalPayload.legalAddress.$dirty) {
                return error;
            }
            if (!this.$v.legalPayload.legalAddress.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.legalPayload.legalAddress.minLength) {
                error.push(this.$t('validation.minLength', { 'size': 5 }));
            }
            if (!this.$v.legalPayload.legalAddress.maxLength) {
                error.push(this.$t('validation.maxLength', { 'size': 50 }));
            }
            return error;
        },
        addressErrors() {
            let error = [];
            if (!this.$v.legalPayload.address.$dirty) {
                return error;
            }
            if (!this.$v.legalPayload.address.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.legalPayload.address.minLength) {
                error.push(this.$t('validation.minLength', { 'size': 5 }));
            }
            if (!this.$v.legalPayload.address.maxLength) {
                error.push(this.$t('validation.maxLength', { 'size': 50 }));
            }
            return error;
        },
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            legalUsersUpdate: 'profile/UPDATE_LEGAL_USER',
            fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
            getLegalUsers: 'profile/GET_LEGAL_USERS',
            getOwnershipTypes: 'profile/GET_OWNERSHIP_TYPES'
        }),
        toggleCardState(e) {
            if (!e.target.closest('.class')) {
                this.expanded = !this.expanded;
                this.legalEdit = false;
                this.onRequestTown()
                this.getOwnershipTypes()
            }

        },
        saveLegalUsersUpdate() {
            if (this.$v.$anyError) return
            if (!this.legalPayload.code && !this.legalPayload.edrpo) return

            const {id, edrpo, code, legalAddress, address, type} = this.legalPayload
            const legalPayloadUpdated = {
                id, edrpo: String(edrpo), tin: String(code),
                juridical_address: this.legalPayload.legalCity.title + ',' + legalAddress,
                real_address: this.legalPayload.city.title + ',' + address, type_id: type.id, name: type.title
            }

            this.legalUsersUpdate(legalPayloadUpdated)
                .then(() => {
                    this.legalEdit = !this.legalEdit
                    this.$emit('to-update')
                })
            this.getLegalUsers()
        },
        onRequestTown(val) {
            const formData = JSON.stringify({
                "modelName": "Address",
                "calledMethod": "getCities",
                "methodProperties": {
                    "FindByString": val
                },
                "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
            })
            this.fetchTowns(formData)
        }
    }
}
